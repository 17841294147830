<section class="error-500 vh-100 d-flex align-items-center">
  <div class="container py-md-8">
    <div class="col px-0">
      <div class="row justify-content-center">
        <div class="col-lg-7 text-center">

          <img
            alt="Flowflex"
            src="../../assets/img/logos/portrait-primary.svg"
            class="w-25 mb-5"
          />
          <h1 class="display-1 mb-3 font-weight-600 text-primary">Ooops!</h1>
          <h5>
            Looks like something broke in our pipework...
          </h5>
          <h5>
            Please come back a little later
          </h5>

        </div>
      </div>
    </div>
  </div>
</section>
