export class ListItemSchema {

  constructor(name: string, position: number, item: string = null) {
    this.name = name;
    this.position = position;
    this.item = item;
  }

  "@type" = "ListItem";
  "position": number;
  "name": string;
  "item": string;
}