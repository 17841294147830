import { Injectable } from '@angular/core';
import { SeoSettings } from 'server/website/settings/schemas/seo-settings.schema';
import { WebsiteSettings } from 'server/website/settings/schemas/website-settings.schema';
import { SchemaService } from './schema.service';
import { WebsiteSchema } from './schemas/website.schema';

@Injectable({
  providedIn: 'root'
})
export class WebsiteSchemaService extends SchemaService {

  publish(seoSettings: SeoSettings, websiteSettings: WebsiteSettings) {
    const schema = new WebsiteSchema();

    schema["@id"] = seoSettings.seo_website_id;
    schema.name = seoSettings.brand_name;
    schema.url = websiteSettings.site_address;

    this.publishSchema(schema);
  }
}