import { Component, Inject, OnInit, Optional } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent implements OnInit {

  constructor(
    private readonly titleService: Title,
    @Optional() @Inject(RESPONSE) private response: any,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Not Found - Flowflex");
    // this.response.statusCode = 404;
  }
}
