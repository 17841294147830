import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  /**
   * Publish Schema to the Document Head
   * @param schema
   */
  publishJsonLd(schema: Object) {
    this.injectScript(
      "application/ld+json",
      JSON.stringify(schema, null, 2),
      true
    );
  }

  /**
   * Set the current page to have no hero
   */
  injectScriptByUrl(url: string, inHead = false) {
    let script = this.document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.type = "text/javascript";
    script.async = true;

    if (inHead) {
      this.document.head.appendChild(script);
    } else {
      this.document.body.appendChild(script);
    }
  }

  /**
   * Inject a script into the page body
   * @param type
   * @param content
   * @param inHead
   */
  injectScript(type: string, content: string, inHead = false) {
    let script = this.document.createElement('script');
    script.type = type;
    script.text = content;

    if (inHead) {
      this.document.head.appendChild(script);
    } else {
      this.document.body.appendChild(script);
    }
  }
}