import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, Router
} from "@angular/router";
import { SettingsService } from './settings.service';
import { WebsiteSettings } from 'server/website/settings/schemas/website-settings.schema';
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class WebsiteSettingsResolver implements Resolve<WebsiteSettings> {
  constructor(private settingsService: SettingsService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WebsiteSettings> {
    return this.settingsService.getWebsiteSettings();
  }
}