import { isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SeoSettings } from 'server/website/settings/schemas/seo-settings.schema';
import { WebsiteSettings } from 'server/website/settings/schemas/website-settings.schema';
import * as urlResolver from 'url';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  public seoSettings: SeoSettings;
  public websiteSettings: WebsiteSettings;

  constructor(
    private meta: Meta,
    private titleService: Title,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  /**
   * Set the SEO settings for the website
   * @param seoSettings
   */
  setSeoSettings(seoSettings: SeoSettings) {
    this.seoSettings = seoSettings;
  }

  /**
   * Set the SEO settings for the website
   * @param seoSettings
   */
  setWebsiteSettings(websiteSettings: WebsiteSettings) {
    this.websiteSettings = websiteSettings;
  }

  /**
   * Set Description Meta Tags
   * @param content
   */
  setDescriptionMeta(content: string) {
    this.meta.updateTag({ name: 'description', content });
    this.meta.updateTag({ name: 'og:description', content });
    this.meta.updateTag({ name: "twitter:description", content })
  }

  /**
   * Set the Page Title
   * @param content
   */
  setPageTitle(content: string = null, suffix: string = null, includeBrand = true) {
    const brand = this.seoSettings.brand_name;
    let title: string;

    if (!content) {
      title = brand;
    } else {
      title = content;

      if (suffix) {
        title += " - " + suffix;
      }

      if (includeBrand) {
        title += ' - ' + brand;
      }
    }

    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'og:title', content: title });
    this.meta.updateTag({ name: 'twitter:title', content: title });
  }

  /**
   * Set any Image Meta Tags
   * @param url
   * @param type
   * @param alt
   */
  setImageMeta(url: string, cms = true, type: string, alt: string = "") {

    if (cms) {
      url = this.cmsUrl(url);
    } else {
      url = this.publicUrl(url);
    }

    this.meta.updateTag({ name: 'og:image', content: url });
    this.meta.updateTag({ name: "twitter:image", content: url });

    this.meta.updateTag({ name: 'og:image:type', content: type });
    this.meta.updateTag({ name: 'og:image:alt', content: alt });
    this.meta.updateTag({ name: 'twitter:image:alt', content: alt });
  }

  /**
   * Set the URL for this page
   */
  setCanonical(): void {
    this.meta.updateTag({ name: 'og:url', content: this.getCanonicalUrl() });
    this.appendCanonicalLinkTag();
  }

  /**
   * Append Canonical Link Ref in Head
   */
  appendCanonicalLinkTag() {
    if (isPlatformServer(this.platformId)) {
      let link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.document.head.appendChild(link);
      link.setAttribute('href', this.getCanonicalUrl());
    }
  }

  /**
   * Return the preferred URL for the current page
   */
  getCanonicalUrl(): string {
    const url = this.router.url.split("?")[0];
    return this.publicUrl(url);
  }

  /**
   * Set the type of twitter card
   */
  setTwitterCard(type: TwitterCard = 'summary') {
    this.meta.updateTag({ name: 'twitter:card', content: type });
  }

  /**
   * Handle Global SEO Settings
   */
  setGlobalSeo() {
    this.meta.updateTag({ name: 'og:site_name', content: this.seoSettings.brand_name });
    this.meta.updateTag({ name: 'copyright', content: this.seoSettings.brand_name });
    this.meta.updateTag({ name: 'copyright', content: this.seoSettings.brand_name });

    this.setTwitterCard();
    this.meta.updateTag({ name: 'twitter:site', content: "@FlowflexUK" });

    this.meta.updateTag({ name: 'og:type', content: "website" });
    this.meta.updateTag({ name: 'og:locale', content: "en_GB" });
    this.meta.updateTag({ name: 'og:site_name', content: this.seoSettings.brand_name });

    this.setImageMeta('/assets/img/logos/social-media-logo.jpg', false, 'image/jpg', this.seoSettings.brand_name);
    this.setCanonical();
  }

  /**
   * Return the public host
   * @param url
   */
  publicUrl(url: string) {
    return urlResolver.resolve(this.websiteSettings.site_address, url);
  }

  /**
   * Return the public host
   * @param url
   */
  cmsUrl(url: string) {
    return urlResolver.resolve(this.websiteSettings.cms_address, url);
  }

  /**
   * Return years of operation
   */
  public getOperatingYears = () => (new Date().getFullYear() - 1954).toString();
}

export type TwitterCard = "summary" | "summary_large_image" | "app" | "player";