import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cookie-consent-modal',
  templateUrl: './cookie-consent-modal.component.html',
})
export class CookieConsentModalComponent implements OnInit {
  constructor(public readonly activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    document.querySelector('body').classList.remove('modal-open');
  }
}
