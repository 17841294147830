export const environment = {
  production: true,
  cms_host: "https://cms.flowflex.app",
  hostUrl: "https://flowflex.app",
  ga: {
    active: true,
    v3: 'UA-82265400-1',
    v4: 'G-J6BYP73CVK'
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZmxvd2ZsZXgiLCJhIjoiY2tobDByMjdkMXJlYzJ6cXE1N3hlOWZ6cyJ9.Wetgean4MPdoVjZFnphbBg'
  }
};
