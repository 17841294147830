import { Injectable } from '@angular/core';
import { Location } from 'server/website/location/schemas/location.schema';
import { SeoSettings } from 'server/website/settings/schemas/seo-settings.schema';
import { WebsiteSettings } from 'server/website/settings/schemas/website-settings.schema';
import { SchemaService } from './schema.service';
import { FounderSchema } from './schemas/founder.schema';
import { LocationSchema } from './schemas/location.schema';
import { OrganisationSchema } from './schemas/organisation.schema';

@Injectable({
  providedIn: 'root'
})
export class OrganisationSchemaService extends SchemaService {

  publish(
    seoSettings: SeoSettings,
    websiteSettings: WebsiteSettings,
    locations: Location[]
  ) {
    const schema = new OrganisationSchema;

    schema["@id"] = seoSettings.seo_organisation_id;

    schema.name = seoSettings.brand_name;

    schema.founders = [];
    schema.founders.push(new FounderSchema("John Dickinson"))
    schema.foundingDate = "1954";

    schema.email = websiteSettings.global_email;
    schema.telephone = websiteSettings.global_tel;

    schema.url = websiteSettings.site_address;

    schema.sameAs = [];
    schema.sameAs.push(websiteSettings.linkedin);
    schema.sameAs.push(websiteSettings.twitter);

    schema.contactPoint = locations.map((loc) => {
      const locSchema = new LocationSchema;
      locSchema.name = "Flowflex " + loc.title;
      locSchema.telephone = loc.tel;
      locSchema.email = loc.email;

      return locSchema;
    });

    this.publishSchema(schema);
  }
}