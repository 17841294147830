import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { LocationsService } from "./locations.service";

@Injectable({
  providedIn: "root"
})
export class LocationsResolver implements Resolve<Location[]> {
  constructor(private locationsService: LocationsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.locationsService.all();
  }
}