<!-- Main content -->
<section class="vh-100 d-flex align-items-center">
  <div
    class="bg-danger position-absolute h-100 top-0 right-0 zindex-110 col-lg-6 col-xl-6 zindex-100 d-none d-lg-flex flex-column justify-content-end rounded-bottom-left"
    data-bg-size="cover"
    data-bg-position="center"
  >
    <!-- Cover image -->
    <img
      src="../../assets/img/about/hero.jpg"
      alt="Image"
      class="img-as-bg rounded-bottom-left"
    />
  </div>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-sm-7 col-lg-6 col-xl-6 mx-auto ml-lg-0">
        <div class="row justify-content-center">
          <div class="col-11 col-lg-10 col-xl-6 py-5">

            <img class="img-fluid w-25 mb-5" src="../../../assets/img/logos/portrait-primary.svg">

            <h1 class="display-1 mb-3 font-weight-600 text-primary">Ooops!</h1>
            <p class="lead text-lg mb-5">
              Sorry, the page you are looking for could not be found.
            </p>
            <a href="/" class="btn btn-primary btn-icon">
              <span class="btn-inner--icon"><i data-feather="home"></i></span>
              <span class="btn-inner--text">Return home</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
