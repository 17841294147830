import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { ReactiveFormsModule } from '@angular/forms';

import { AnyQuestionsComponent } from './block-any-questions/block-any-questions.component';
import { LocationMapComponent } from './maps/location-map.component';
import { HeroComponent } from './hero/hero.component';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { ContactFormComponent } from './block-contact-form/block-contact-form.component';
import { ProductRangeTechnicalSectionBlock } from './block-product-range-technical/block-product-range-technical.component';
import { FAQComponent } from './block-faq/block-faq.component';

import { CtaButtonComponent } from './btn-cta/btn-cta.component';
import { SlickCarouselComponent } from './slick-carousel/slick-carousel.component';

import { DownloadCardComponent } from './card-download/card-download.component';
import { ProductCardComponent } from './card-product/card-product.component';
import { ProductRangeCardComponent } from './card-product-range/card-product-range.component';
import { ArticleCardComponent } from './card-article/card-article.component';
import { CaseStudyCardComponent } from './card-case-study/card-case-study.component';

import { ProductSliderComponent } from './slider-product/slider-product.component';
import { CaseStudiesSliderComponent } from './slider-case-studies/slider-case-studies.component';
import { ProductRangeSliderComponent } from './slider-product-range/slider-product-range.component';

import { ScrollTopComponent } from './scroll-top/scroll-top.component';

import { PipesModule } from '../pipes/pipes.module';
import { DirectivesModule } from '../directives/directives.module';
import { ProductRangesService } from '../services/product-ranges/product-ranges.service';
import { TrustPilotCarouselComponent } from './trustpilot/trustpilot-carousel.component';
import { BtnAboutVideoComponent } from './btn-about-video/btn-about-video.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { BlockSectionComponent } from './block-section/block-section.component';
import { ImgStripBlockComponent } from './block-img-strip/block-img-strip.component';
import { BtnBuyComponent } from './tracking/btn-buy.component';
import { BtnContactComponent } from './tracking/btn-contact.component';
import { CookieConsentModalComponent } from './cookie-consent-modal/cookie-consent-modal.component';

@NgModule({
  declarations: [
    AnyQuestionsComponent,
    ArticleCardComponent,
    BtnAboutVideoComponent,
    BtnBuyComponent,
    BtnContactComponent,
    HeaderComponent,
    HeroComponent,
    FooterComponent,
    BlockSectionComponent,
    CaseStudyCardComponent,
    CaseStudiesSliderComponent,
    ContactFormComponent,
    CtaButtonComponent,
    FAQComponent,
    ImgStripBlockComponent,
    LocationMapComponent,
    SlickCarouselComponent,
    ProductSliderComponent,
    ProductRangeSliderComponent,
    DownloadCardComponent,
    ProductCardComponent,
    ProductRangeCardComponent,
    ProductRangeTechnicalSectionBlock,
    ScrollTopComponent,
    TrustPilotCarouselComponent,
    VideoPlayerComponent,
    CookieConsentModalComponent,
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Le-YtAZAAAAAGWAOv1rn2wty4FtOhGYvlICp87Q' },
    ProductRangesService,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    SlickCarouselModule,
    PipesModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    NgbModalModule,
  ],
  exports: [
    AnyQuestionsComponent,
    ArticleCardComponent,
    BtnAboutVideoComponent,
    BtnBuyComponent,
    BtnContactComponent,
    HeaderComponent,
    HeroComponent,
    FooterComponent,
    BlockSectionComponent,
    CaseStudyCardComponent,
    CaseStudiesSliderComponent,
    ContactFormComponent,
    CtaButtonComponent,
    FAQComponent,
    ImgStripBlockComponent,
    LocationMapComponent,
    ProductSliderComponent,
    ProductRangeSliderComponent,
    DownloadCardComponent,
    ProductCardComponent,
    ProductRangeCardComponent,
    ProductRangeTechnicalSectionBlock,
    ScrollTopComponent,
    TrustPilotCarouselComponent,
    VideoPlayerComponent,
    CookieConsentModalComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ]
})
export class BlocksModule { }
