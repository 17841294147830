import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServicesModule } from '../services.module';
import { ApprenticeshipSchemeSettings } from 'server/website/settings/schemas/apprenticeship-scheme-settings.schema';
import { SeoSettings } from 'server/website/settings/schemas/seo-settings.schema';
import { WebsiteSettings } from 'server/website/settings/schemas/website-settings.schema';

@Injectable({
  providedIn: ServicesModule
})
export class SettingsService {

  constructor(private readonly $http: HttpClient) { }

  getApprenticeshipSchemeSettings(): Observable<ApprenticeshipSchemeSettings> {
    return this.$http.get<ApprenticeshipSchemeSettings>(`/api/settings/apprenticeship-scheme-settings`);
  }

  getSeoSettings(): Observable<SeoSettings> {
    return this.$http.get<SeoSettings>(`/api/settings/seo-settings`);
  }

  getWebsiteSettings(): Observable<WebsiteSettings> {
    return this.$http.get<WebsiteSettings>(`/api/settings/website-settings`);
  }
}
