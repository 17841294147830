import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { ScriptLoaderService } from '../misc/script-loader.service';
import { SeoService } from '../misc/seo.service';
import { BreadcrumbListSchema } from './schemas/breadcrumb-list.schema';
import { ListItemSchema } from './schemas/list-item.schema';
import { LogoSchema } from './schemas/logo.schema';
import { OrganisationSchema } from './schemas/organisation.schema';
import { capitalCase } from 'change-case';

@Injectable({
  providedIn: 'root'
})
export abstract class SchemaService {

  private breadcrumbs: ListItemSchema[] = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly scriptsService: ScriptLoaderService,
    public readonly seoService: SeoService,
    public readonly router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  /**
   * Publish a schema
   * @param schema
   */
  public publishSchema(schema: Object) {
    if (isPlatformServer(this.platformId)) {
      this.scriptsService.publishJsonLd(schema);
    }
  }

  /**
   * Construct a Short Schema Version
   */
  getOrganisationSubSchema() {
    const schema = new OrganisationSchema;
    schema["@id"] = this.seoService.seoSettings.seo_organisation_id;
    schema.name = this.seoService.seoSettings.brand_name;
    schema.logo = new LogoSchema;
    return schema;
  }

  /**
   * Add Level to Breadcrumbs
   * @param name
   * @param url
   */
  pushBreadcrumb(name: string, url: string = null) {
    const level = this.breadcrumbs.length + 1;
    if (url) { url = this.publicUrl(url); }
    this.breadcrumbs.push(new ListItemSchema(name, level, url));
  }

  /**
   * Publish Breadcrumb Schema
   */
  publishBreadcrumbs() {
    this.mapBreadcrumbs(this.activatedRoute);

    if (this.breadcrumbs.length) {
      const schema = new BreadcrumbListSchema();
      schema.itemListElement = this.breadcrumbs;
      this.publishSchema(schema);
    }
  }

  /**
   * Map The Route Hierarchy for Breadcrumbs
   */
  mapBreadcrumbs(route: ActivatedRoute) {

    if (route.snapshot.routeConfig && route.snapshot.routeConfig.path !== "") {
      const name = capitalCase(route.snapshot.url[0].path.toLowerCase());
      const url = route.pathFromRoot
        .map(route => route.snapshot.url.map((segment: UrlSegment) => segment.path).join("/"))
        .filter(path => path.length)
        .join("/");

      this.pushBreadcrumb(name, `/${url}`);
    }

    if (route.firstChild) {
      this.mapBreadcrumbs(route.firstChild);
    }
  }

  /**
   * Return the public host
   * @param url
   */
  publicUrl(url: string): string {
    return "https://flowflex.com" + url;
  }

  /**
   * Return the public host
   * @param url
   */
  cmsUrl(url: string): string {
    return "https://cms.flowflex.app" + url;
  }
}