import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentComponent } from './content/content.component';
import { ErrorComponent } from './errors/error/error.component';
import { PageNotFoundComponent } from './errors/page-not-found/page-not-found.component';

import { LocationsResolver } from './shared/services/locations/locations.resolver';
import { ProductCategoriesResolver } from './shared/services/product-categories/product-categories.resolver';
import { SeoSettingsResolver } from './shared/services/settings/seo-settings.resolver';
import { WebsiteSettingsResolver } from './shared/services/settings/website-settings.resolver';

const routes: Routes = [

  ////////////////////////////////////////////////////////
  // Old Website Redirects
  //

  // Article Redirects
  // TODO: Make Support Articles Page
  { path: 'resources/articles/dzr-components', redirectTo: '/support/articles/dzr-components', pathMatch: 'full' },
  { path: 'resources/articles/stress-corrosion-cracking', redirectTo: '/support/articles/stress-corrosion-cracking', pathMatch: 'full' },
  { path: 'resources/articles/hydraulic-shock', redirectTo: '/support/articles/hydraulic-shock', pathMatch: 'full' },
  { path: 'resources/articles/erosion-corrosion', redirectTo: '/support/articles/erosion-corrosion', pathMatch: 'full' },
  { path: 'resources/articles/flux-induced-pitting', redirectTo: '/support/articles/flux-induced-pitting', pathMatch: 'full' },
  { path: 'resources/articles/copper-brass-or-bronze', redirectTo: '/support/articles/copper-brass-or-bronze', pathMatch: 'full' },
  { path: 'news-updates/top-up-mate', redirectTo: '/news/top-up-mate-launched-keep-your-customers-warm-this-winter', pathMatch: 'full' },

  // Markets Redirects
  { path: 'consultant', redirectTo: '/markets/consultant', pathMatch: 'full' },
  { path: 'contractor', redirectTo: '/markets/contractor', pathMatch: 'full' },
  { path: 'merchant', redirectTo: '/markets/merchant', pathMatch: 'full' },
  { path: 'oem', redirectTo: '/markets/oem', pathMatch: 'full' },

  // Legal Redirects
  { path: 'cookie-policy', redirectTo: '/policies/cookies-policy', pathMatch: 'full' },
  { path: 'privacy-policy', redirectTo: '/policies/privacy-policy', pathMatch: 'full' },
  { path: 'quality-policy', redirectTo: '/policies/quality-policy', pathMatch: 'full' },
  { path: 'terms', redirectTo: '/policies/terms-and-conditions', pathMatch: 'full' },

  // Range Redirects
  { path: 'compression/technical', redirectTo: '/ranges/compression/technical', pathMatch: 'full' },
  { path: 'pex/technical', redirectTo: '/ranges/pex/technical', pathMatch: 'full' },
  { path: 'copper-press/technical', redirectTo: '/ranges/copper-press/technical', pathMatch: 'full' },
  { path: 'end-feed/technical', redirectTo: '/ranges/end-feed/technical', pathMatch: 'full' },
  { path: 'solder-ring/technical', redirectTo: '/ranges/solder-ring/technical', pathMatch: 'full' },

  { path: 'valves/compression', redirectTo: '/categories/valves', pathMatch: 'full' },
  { path: 'valves/check-valves', redirectTo: '/categories/valves', pathMatch: 'full' },
  { path: 'valves/gate-valves', redirectTo: '/ranges/gate-valves', pathMatch: 'full' },
  { path: 'valves/ball-valves', redirectTo: '/ranges/ball-valves', pathMatch: 'full' },
  { path: 'valves/angle-valves', redirectTo: '/ranges/angle-valves', pathMatch: 'full' },

  ////////////////////////////////////////////////////////
  // Main Routes
  //

  {
    path: '',
    component: ContentComponent,
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    resolve: {
      locations: LocationsResolver,
      productCategories: ProductCategoriesResolver,
      seoSettings: SeoSettingsResolver,
      websiteSettings: WebsiteSettingsResolver,
    },
  },

  ////////////////////////////////////////////////////////
  // Error Pages
  //

  {
    path: '500',
    component: ErrorComponent,
    data: {
      title: "Error"
    }
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    data: {
      title: "Not Found"
    }
  },
  {
    path: '**',   // Wildcard route for a 404 page
    redirectTo: '404'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRouter { }
