<footer class="position-relative text-center text-lg-left" id="footer-main">
  <!-- SVG shape -->
  <div class="shape-container shape-position-top shape-orientation-inverse">
    <svg
      width="2560px"
      height="100px"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="none"
      x="0px"
      y="0px"
      viewBox="0 0 2560 100"
      style="enable-background: new 0 0 2560 100"
      xml:space="preserve"
      class=""
    >
      <polygon points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
  <div class="pt-7">
    <!-- Footer -->
    <div class="container pt-4">
      <div class="row">
        <div class="col-12 col-sm-7 text-center text-sm-left">
          <h3 class="mb-0 text-white">Flowflex UK</h3>
          <p class="lead mt-0 text-white opacity-8 h6">
            <i>Performance Engineering</i>
          </p>
        </div>
        <div class="col-12 col-sm-5 text-center text-sm-right">
          <a href="/contact" class="btn btn-sm btn-icon my-2">
            <i class="las la-phone"></i>
            <span class="btn-inner--text">Contact Us</span>
          </a>
        </div>
      </div>
      <hr class="divider divider-fade divider-dark my-5" />
      <div class="row">
        <div class="col-lg-4 mb-5 mb-lg-0 text-sm">
          <!-- Theme's logo -->
          <a href="/">
            <img
              alt="Image placeholder"
              src="../../assets/img/logos/portrait-white.svg"
              class="w-25"
            />
          </a>
          <!-- Webpixels' mission -->
          <div class="mt-4">
            <p class="mb-0">Flowflex Components LTD</p>
            <p class="mb-0" *ngIf="headOffice && headOffice.address_1">
              {{ headOffice.address_1 }}
            </p>
            <p class="mb-0" *ngIf="headOffice && headOffice.address_2">
              {{ headOffice.address_2 }}
            </p>
            <p class="mb-0" *ngIf="headOffice && headOffice.city">
              {{ headOffice.city }}
            </p>
            <p class="mb-0" *ngIf="headOffice && headOffice.post_code">
              {{ headOffice.post_code }}
            </p>
            <p class="mb-0" *ngIf="headOffice && headOffice.country">
              {{ headOffice.country }}
            </p>
          </div>
          <!-- Social -->
          <ul class="nav mt-4 justify-content-center justify-content-lg-start">
            <li class="nav-item">
              <app-btn-contact
                classes="nav-link pl-lg-0"
                [iconOnly]="true"
                [value]="settings.global_tel"
                [email]="false"
              ></app-btn-contact>
            </li>
            <li class="nav-item">
              <app-btn-contact
                classes="nav-link"
                [iconOnly]="true"
                [value]="settings.global_email"
                [email]="true"
              ></app-btn-contact>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                rel="noreferrer"
                [href]="settings.linkedin"
                target="_blank"
              >
                <i class="lab la-linkedin"></i>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                rel="noreferrer"
                [href]="settings.twitter"
                target="_blank"
              >
                <i class="lab la-twitter"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-6 col-sm-4 ml-lg-auto mb-lg-0 order-1">
          <h6 class="heading mb-3">Company</h6>
          <ul class="list-unstyled footer-menu text-lowercase mb-0">
            <li><a href="/about">About Us</a></li>
            <li><a href="/markets">Markets</a></li>
            <li><a href="/careers">Careers</a></li>
            <li><a href="/news">News & Updates</a></li>
            <li><a href="/contact">Contact Us</a></li>
          </ul>
        </div>
        <div
          class="col-lg-2 col-12 col-sm-4 mb-lg-0 order-3 order-sm-2 mt-5 mt-sm-0"
        >
          <h6 class="heading mb-3">Products</h6>
          <ul class="list-unstyled footer-menu text-lowercase mb-0">
            <li *ngFor="let category of productCategories">
              <a [href]="'/categories/' + category.slug">
                {{ category.name }}
              </a>
            </li>
            <li><a href="/markets/oem">OEM</a></li>
          </ul>
        </div>
        <div class="col-lg-2 col-6 col-sm-4 mb-lg-0 order-2 order-sm-3">
          <h6 class="heading mb-3">Support</h6>
          <ul class="list-unstyled footer-menu text-lowercase mb-0">
            <li><a href="/case-studies">Case Studies</a></li>
            <li><a href="/downloads">Downloads</a></li>
            <li><a href="/support">Knowledge Base</a></li>
            <li><a href="/policies">Policies</a></li>
          </ul>
        </div>
      </div>
      <hr class="divider divider-fade divider-dark my-4" />
      <div class="row align-items-center justify-content-md-between pb-4">
        <div class="col-md-6">
          <div class="copyright text-xs text-center text-md-left">
            &copy; Copyright
            <a
              href="https://flowflex.com"
              rel="noreferrer"
              class=""
              target="_blank"
            >
              Flowflex Holdings
            </a>
            {{ year() }}. All rights reserved. Flowflex, Lamontite, and certain
            product names used herein are trademarks or registered trademarks of
            Flowflex Holdings Limited. See trademarks or appropriate markings.
          </div>
        </div>
        <div class="col-md-6">
          <ul
            class="nav text-lowercase justify-content-center justify-content-md-end mt-3 mt-md-0"
          >
            <li class="nav-item">
              <a class="nav-link" href="/policies/terms-and-conditions">
                Terms
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/policies/quality-policy">Quality</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/policies/privacy-policy">Privacy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/policies/cookies-policy">Cookies</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
