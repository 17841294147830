import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, Router
} from "@angular/router";
import { SettingsService } from './settings.service';
import { SeoSettings } from 'server/website/settings/schemas/seo-settings.schema';

@Injectable({
  providedIn: "root"
})
export class SeoSettingsResolver implements Resolve<SeoSettings> {
  constructor(private settingsService: SettingsService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.settingsService.getSeoSettings();
  }
}