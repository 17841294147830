import { NgModule } from '@angular/core';

@NgModule({
  imports: [

  ],
  providers: [

  ],
  exports: [

  ]
})
export class ServicesModule { }
