import { Component, Inject, OnInit, Optional } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {

  constructor(
    private readonly titleService: Title,
    @Optional() @Inject(RESPONSE) private response: any,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Error - Flowflex");
    // this.response.statusCode = 500;
  }
}
