<div class="row align-items-center">
  <div class="col-12 col-md-6 text-center text-md-left mb-3 mb-md-0">
    <p>
      We use cookies and similar technologies to improve your browsing
      experience on our website and to understand your interaction with our
      service. By clicking on accept, you agree to our use of such techologies.
      <a href="/policies/privacy-policy">See our Privacy Policy</a>
    </p>
  </div>
  <div class="col-12 col-md-6 text-center text-md-right">
    <button class="btn btn-sm btn-light" (click)="activeModal.close(false)">
      I Decline
    </button>
    <button class="btn btn-sm btn-primary" (click)="activeModal.close(true)">
      I Accept
    </button>
  </div>
</div>
