import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from 'server/website/location/schemas/location.schema';
import { SeoSettings } from 'server/website/settings/schemas/seo-settings.schema';
import { WebsiteSettings } from 'server/website/settings/schemas/website-settings.schema';
import { SeoService } from '../shared/services/misc/seo.service';
import { WebsiteSchema } from '../shared/services/schema/schemas/website.schema';
import { OrganisationSchema } from '../shared/services/schema/schemas/organisation.schema';
import { WebsiteSchemaService } from '../shared/services/schema/website-schema.service';
import { OrganisationSchemaService } from '../shared/services/schema/organisation-schema.service';
import { SchemaService } from '../shared/services/schema/schema.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
})
export class ContentComponent implements OnInit {

  public settings: WebsiteSettings;
  public address: string[];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly seoService: SeoService,
    private readonly schemaService: SchemaService,
    private readonly websiteSchemaService: WebsiteSchemaService,
    private readonly organisationSchemaService: OrganisationSchemaService
  ) { }

  ngOnInit(): void {

    this.activatedRoute.data.subscribe((data: {
      locations: Location[],
      seoSettings: SeoSettings,
      websiteSettings: WebsiteSettings
    }) => {
      this.settings = data.websiteSettings;

      this.seoService.setSeoSettings(data.seoSettings);
      this.seoService.setWebsiteSettings(data.websiteSettings);

      this.seoService.setGlobalSeo();

      this.websiteSchemaService.publish(data.seoSettings, data.websiteSettings);
      this.organisationSchemaService.publish(data.seoSettings, data.websiteSettings, data.locations);
    });

    this.schemaService.publishBreadcrumbs();
  }
}
