import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpInterceptors } from './shared/interceptors/http.interceptor';

import { AppRouter } from './app.router';
import { AppComponent } from './app.component';
import { CookieService } from 'ngx-cookie-service';
import { ContentComponent } from './content/content.component';
import { ErrorComponent } from './errors/error/error.component';
import { PageNotFoundComponent } from './errors/page-not-found/page-not-found.component';
import { ServicesModule } from './shared/services/services.module';
import { HttpClientModule } from '@angular/common/http';
import { BlocksModule } from './shared/blocks/blocks.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorInterceptors } from './shared/interceptors/error.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    PageNotFoundComponent,
    ContentComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ngPublic' }),
    BlocksModule,
    AppRouter,
    HttpClientModule,
    ServicesModule,
    NgbModule,
  ],
  providers: [
    CookieService,
    HttpInterceptors,
    ErrorInterceptors,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
