import { Component, Input } from '@angular/core';
import { AnalyticsService } from '../../services/misc/analytics.service';

@Component({
  selector: 'app-btn-contact',
  template: `
    <a
      target="_blank"
      (click)="tag()"
      [class]="class"
      [href]="href"
      [innerHTML]="html"
    ></a>
  `
})
export class BtnContactComponent {

  @Input('classes') class = "";
  @Input() iconOnly = false;
  @Input() icon = false;
  @Input() email = false;
  @Input() value: string;

  constructor(
    private readonly analytics: AnalyticsService
  ) { }

  tag() {
    this.analytics.tagDirectContact(this.email, this.value);
  }

  get subject() {
    return ((this.email) ? "Email" : "Tel") + ": " + this.value;
  }

  get href() {
    let href = ((this.email) ? "mailto:" : "tel:") + this.value;

    if (this.email) {
      href += '?subject=Website Enquiry';
    }

    return href;
  }

  get html() {
    if (this.iconOnly) {
      if (this.email) {
        return "<i class='las la-envelope'></i>";
      } else {
        return "<i class='las la-phone'></i>";
      }
    } else {
      return this.value;
    }
  }

}
