import { AfterViewInit, Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { CookieConsentModalComponent } from './shared/blocks/cookie-consent-modal/cookie-consent-modal.component';
import { AnalyticsService } from './shared/services/misc/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit {
  public title = 'Flowflex';

  protected trackingConsentCookie = 'tracking_consent';

  constructor(
    private modalService: NgbModal,
    private readonly analyticsService: AnalyticsService,
    private readonly cookieService: CookieService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const exists = this.cookieService.check(this.trackingConsentCookie);

    if (!exists) {
      this.deleteAllCookies();

      const modal = this.modalService.open(CookieConsentModalComponent, {
        backdrop: false,
        windowClass: 'cookie-consent-modal',
      });

      modal.result.then((consent) => {
        if (consent) {
          this.consentedToTracking();
        } else {
          this.declinedTracking();
        }
      });
    } else {
      const consented = this.cookieService.get(this.trackingConsentCookie);

      if (consented === 'true') {
        this.consentedToTracking();
      } else {
        this.declinedTracking();
      }
    }
  }

  consentedToTracking(): any {
    this.setTrackingConsentCookie('true');
    this.analyticsService.setAnalyticsConsent(true);
    this.analyticsService.injectGaScripts();
  }

  /**
   * Apply steps if someone declines to be tracked
   */
  declinedTracking(): any {
    this.deleteAllCookies();
    this.analyticsService.setAnalyticsConsent(false);
    this.setTrackingConsentCookie('false');
  }

  deleteAllCookies() {
    sessionStorage.clear();
    localStorage.clear();
    this.cookieService.deleteAll('/');
    // We need to have a dot preceding the hostname for crossdomain ga cookies
    this.cookieService.deleteAll('/', '.' + window.location.hostname);
  }

  setTrackingConsentCookie(value) {
    this.cookieService.set(this.trackingConsentCookie, value, {
      path: '/',
    });
  }
}
