/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './http-interceptors/lowercase-url.serializer';
import { HttpRequestInterceptor } from './http-interceptors/request.interceptor';
import { HttpResponseInterceptor } from './http-interceptors/response.interceptor';

/** Http interceptor providers in outside-in order */
export const HttpInterceptors = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true },
  { provide: UrlSerializer, useClass: LowerCaseUrlSerializer }
];