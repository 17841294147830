import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";
import { ProductCategoriesService } from './product-category.service';
import { ProductCategory } from 'server/website/product-category/schemas/product-category.schema';

@Injectable({
  providedIn: "root"
})
export class ProductCategoriesResolver implements Resolve<ProductCategory[]> {
  constructor(private productCategoryService: ProductCategoriesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProductCategory[]> {
    return this.productCategoryService.all();
  }
}