<header
  #header
  id="header-main"
  [class.--ffx--mobile-menu-active]="mobileMenuActive"
  (window:scroll)="onWindowScroll($event)"
  (mouseenter)="onHover($event)"
  (mouseleave)="onBlur($event)"
>
  <!-- Navbar -->
  <nav
    class="navbar fixed-top navbar-main navbar-expand-lg navbar-light"
    id="navbar-main"
  >
    <div class="container">
      <!-- Brand -->
      <a class="navbar-brand" href="/">
        <img
          alt="Image placeholder"
          src="../../assets/img/logos/base-primary.svg"
          id="navbar-logo"
        />
      </a>
      <!-- Toggler -->
      <button (click)="toggleMobileMenu()" class="navbar-toggler" type="button">
        <i *ngIf="mobileMenuActive" class="las la-times"></i>
        <i *ngIf="!mobileMenuActive" class="las la-bars"></i>
      </button>
      <!-- Collapse -->
      <div
        class="collapse navbar-collapse navbar-collapse-overlay"
        id="navbar-main-collapse"
      >
        <!-- Main navigation -->
        <ul class="navbar-nav ml-lg-auto">
          <!-- Pages menu -->
          <li
            class="nav-item nav-item-spaced dropdown dropdown-animate"
            #subMenu
            (mouseenter)="subMenuEnter($event)"
            (mouseleave)="subMenuLeave($event)"
            data-toggle="hover"
          >
            <a class="nav-link" href="/markets">markets</a>
            <div class="--ffx--sub-menu">
              <ul class="list-unstyled">
                <li><a href="/markets/consultant">Consultant</a></li>
                <li><a href="/markets/contractor">Contractor</a></li>
                <li><a href="/markets/merchant">Merchant</a></li>
                <li><a href="/markets/oem">OEM</a></li>
              </ul>
            </div>
          </li>
          <!-- Landings menu -->
          <li
            class="nav-item nav-item-spaced dropdown dropdown-animate"
            #subMenu
            (mouseenter)="subMenuEnter($event)"
            (mouseleave)="subMenuLeave($event)"
            data-toggle="hover"
          >
            <a class="nav-link" href="/products">Products</a>
            <div class="--ffx--sub-menu">
              <ul class="list-unstyled">
                <li *ngFor="let category of productCategories">
                  <a [href]="'/categories/' + category.slug">
                    {{ category.name }}
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <!-- App menu -->
          <li
            class="nav-item nav-item-spaced dropdown dropdown-animate"
            #subMenu
            (mouseenter)="subMenuEnter($event)"
            (mouseleave)="subMenuLeave($event)"
            data-toggle="hover"
          >
            <a class="nav-link" href="/support" role="button">Support</a>
            <div class="--ffx--sub-menu">
              <ul class="list-unstyled">
                <li><a href="/downloads">Downloads</a></li>
                <li><a href="/case-studies">Case Studies</a></li>
                <li><a href="/support">Knowledge Base</a></li>
                <li><a href="/policies">Policies</a></li>
              </ul>
            </div>
          </li>
          <!-- Docs menu -->
          <li
            class="nav-item nav-item-spaced dropdown dropdown-animate"
            data-toggle="hover"
          >
            <a class="nav-link" href="/contact">contact</a>
          </li>
          <li
            class="nav-item nav-item-spaced dropdown dropdown-animate"
            data-toggle="hover"
          >
            <a class="nav-link" href="/search">
              <i class="las la-search"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>

<header
  #mobileMenu
  [class.active]="mobileMenuActive"
  class="mobileMenuActive"
  id="header-mobile"
>
  <div class="menu">
    <div class="menu-inner">
      <a (click)="deactivateMobileSubMenu()" class="back-btn">
        <i class="las la-angle-left"></i>
        <span class="ml-2">Back</span>
      </a>

      <ul>
        <li>
          <a class="menu-item" href="/">home</a>
        </li>
        <!-- Pages menu -->
        <li (click)="activateMobileSubMenu($event)" class="--ffx-has-sub-menu">
          <div class="menu-item">markets</div>
          <div #subMenu class="--ffx--sub-menu">
            <ul>
              <li>
                <a class="menu-item" href="/markets/consultant">Consultant</a>
              </li>
              <li>
                <a class="menu-item" href="/markets/contractor">Contractor</a>
              </li>
              <li>
                <a class="menu-item" href="/markets/merchant">Merchant</a>
              </li>
              <li><a class="menu-item" href="/markets/oem">OEM</a></li>
            </ul>
          </div>
        </li>
        <!-- Landings menu -->
        <li (click)="activateMobileSubMenu($event)" class="--ffx-has-sub-menu">
          <div class="menu-item">products</div>
          <div #subMenu class="--ffx--sub-menu">
            <ul>
              <li *ngFor="let category of productCategories">
                <a class="menu-item" [href]="'/categories/' + category.slug">
                  {{ category.name }}
                </a>
              </li>
            </ul>
          </div>
        </li>
        <!-- App menu -->
        <li (click)="activateMobileSubMenu($event)" class="--ffx-has-sub-menu">
          <div class="menu-item">support</div>
          <div #subMenu class="--ffx--sub-menu">
            <ul>
              <li><a class="menu-item" href="/downloads">Downloads</a></li>
              <li>
                <a class="menu-item" href="/case-studies">Case Studies</a>
              </li>
              <li><a class="menu-item" href="/support">Knowledge Base</a></li>
              <li><a class="menu-item" href="/policies">Policies</a></li>
            </ul>
          </div>
        </li>
        <!-- Docs menu -->
        <li>
          <a class="menu-item" href="/careers">Careers</a>
        </li>
        <li>
          <a class="menu-item" href="/contact">contact</a>
        </li>
        <li>
          <a class="menu-item" href="/search">
            <i class="las la-search"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</header>
