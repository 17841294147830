import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServicesModule } from '../services.module';
import { Product } from 'server/website/product/schemas/product.schema';
import { Download } from 'server/website/download/schemas/download.schema';
import { Observable } from 'rxjs';

import { ProductRange } from 'server/website/product-range/schemas/product-range.schema';
import { InstallationInstruction } from 'server/website/product-range/schemas/installation-instruction.schema';
import { SpecificationClause } from 'server/website/product-range/schemas/specification-clause.schema';
import { TechnicalInformation } from 'server/website/product-range/schemas/technical-information.schema';
import { Inventory } from 'server/website/product/schemas/inventory.schema';

@Injectable({
  providedIn: ServicesModule
})
export class ProductRangesService {

  constructor(private readonly $http: HttpClient) { }

  all(): Observable<ProductRange[]> {
    return this.$http.get<ProductRange[]>(`/api/product-ranges`);
  }

  find(slug: any): Observable<ProductRange> {
    return this.$http.get<ProductRange>(`/api/product-ranges/${slug}`);
  }

  findDownloads(slug: string): Observable<Download[]> {
    return this.$http.get<Download[]>(`/api/product-ranges/${slug}/downloads`);
  }

  findProducts(slug: string): Observable<Product[]> {
    return this.$http.get<Product[]>(`/api/product-ranges/${slug}/products`);
  }

  findInventory(slug: string): Observable<Inventory[]> {
    return this.$http.get<Inventory[]>(`/api/product-ranges/${slug}/inventory`);
  }

  findInstallation(slug: string): Observable<InstallationInstruction> {
    return this.$http.get<InstallationInstruction>(`/api/product-ranges/${slug}/installation`);
  }

  findSpecification(slug: string): Observable<SpecificationClause> {
    return this.$http.get<SpecificationClause>(`/api/product-ranges/${slug}/specification`);
  }

  findTechnical(slug: string): Observable<TechnicalInformation> {
    return this.$http.get<TechnicalInformation>(`/api/product-ranges/${slug}/technical`);
  }
}
