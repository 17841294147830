import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServicesModule } from '../services.module';
import { isNull } from 'lodash';
import { Observable } from 'rxjs';
import { ProductCategory } from 'server/website/product-category/schemas/product-category.schema';

@Injectable({
  providedIn: ServicesModule
})
export class ProductCategoriesService {

  constructor(private readonly $http: HttpClient) { }

  all(): Observable<ProductCategory[]> {
    return this.$http.get<ProductCategory[]>(`/api/product-categories`);
  }

  find(slug: any): Observable<ProductCategory> {
    return this.$http.get<ProductCategory>(`/api/product-categories/${slug}`);
  }

  getRanges(slug: any) {
    return this.$http.get<any>(`/api/product-categories/${slug}/product-ranges`);
  }

  getProducts(slug: any, limit: number = null) {
    limit = (isNull(limit)) ? -1 : limit;
    return this.$http.get<any>(`/api/product-categories/${slug}/products?_limit=${limit}`);
  }
}
