import { FounderSchema } from './founder.schema';
import { LocationSchema } from './location.schema';
import { LogoSchema } from './logo.schema';

export class OrganisationSchema {
  "@context" = "http://schema.org";
  "@id": string;
  "@type" = "Organization";
  "name": string; // Public Brand Name
  "legalName"?: string;
  "founders"?: FounderSchema[];
  "foundingDate": string;
  "email"?: string;
  "telephone"?: string;
  "url"?: string; // Public Address
  "logo" = new LogoSchema();
  "contactPoint"?: LocationSchema[];
  "sameAs"?: string[] // Social Addresses
}