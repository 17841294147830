import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Download } from 'server/website/download/schemas/download.schema';
import { File } from 'server/website/misc/schemas/file.schema';
import { Product } from 'server/website/product/schemas/product.schema';
import { environment } from 'src/environments/environment';
import { CMSUrlPipe } from '../../pipes/cms-url/cms-url.pipe';
import { FileSizePipe } from '../../pipes/file-size/file-size.pipe';
import { ScriptLoaderService } from './script-loader.service';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private consentedToAnalytics: boolean = false;

  constructor(
    private readonly scriptsService: ScriptLoaderService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  /**
   * Tag when a user views a product
   * @param product
   */
  tagProductView = (product: Product) =>
    this.eventEmitter('view_item', {
      event_category: 'engagement',
      event_label: product.code,
    });

  /**
   * Tag event when video is opened
   * @param tag
   * @param video
   */
  tagVideoView = (tag: string, video: File) =>
    this.eventEmitter('view_video', {
      event_category: 'engagement',
      event_label: tag,

      // Custom Parameters
      title: video.name,
      url: new CMSUrlPipe().transform(video.url),
      mime: video.mime,
      size: new FileSizePipe().transform(video.size),
    } as VideoViewOptionsInterface);

  /**
   * Tag an Purchase Referral
   * @param inventory
   */
  tagPurchaseReferral = (stock_code: string, merchant: string = 'amazon') =>
    this.eventEmitter('purchase_referral', {
      event_category: 'ecommerce',
      event_label: stock_code,

      // Custom Parameters
      merchant: merchant,
    } as PurchaseReferralOptionsInterface);

  /**
   * Tag when user contacts directly, i.e by clicking an email or tel link
   * @param email
   * @param subject
   */
  tagDirectContact(email: boolean, subject: string) {
    const label = email ? 'Email' : 'Tel';
    this.tagLeadGeneration(label, subject);
  }

  /**
   * Tag when someone uses the contact form
   * @param subject
   */
  tagFormContact = (subject: string) =>
    this.tagLeadGeneration('contact_form', subject);

  /**
   * Tag when a lead is generated, i.e. when someone makes contact with us
   * @param label
   * @param subject
   */
  private tagLeadGeneration = (label: string, subject: string) =>
    this.eventEmitter('generate_lead', {
      event_category: 'engagement',
      event_label: label,

      // Custom Parameters
      subject: subject,
    } as EnquiryOptionsInterface);

  /**
   * Tag a File Download
   */
  tagFileDownload = (download: Download) =>
    this.eventEmitter('file_download', {
      event_category: 'engagement',
      event_label: download.type,

      // Enhanced Parameters
      file_extension: download.mime,
      file_name: download.title,
      link_url: new CMSUrlPipe().transform(
        '/downloads/' + download.id + '/download'
      ),
      link_text: null,

      // Custom Parameters
      file_size: new FileSizePipe().transform(download.size),
    } as FileDownloadOptionsInterface);

  /**
   * Send Event to Analytics Service
   * @param eventName
   * @param eventCategory
   * @param eventLabel
   * @param eventValue
   */
  private eventEmitter(eventName: string, options: GTagEventOptionsInterface) {
    if (
      isPlatformBrowser(this.platformId) &&
      environment.ga.active &&
      this.consentedToAnalytics
    ) {
      gtag('event', eventName, options);
    }
  }

  /**
   * Inject Analytics Scripts into Document Body
   */
  injectGaScripts() {
    if (isPlatformBrowser(this.platformId) && environment.ga.active) {
      this.setAnalyticsConsent(true);
      this.scriptsService.injectScriptByUrl(
        `https://www.googletagmanager.com/gtag/js?id=${environment.ga.v3}`
      );
      this.scriptsService.injectScript(
        'text/javascript',
        `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${environment.ga.v3}');
          gtag('config', '${environment.ga.v4}');
        `
      );
    }
  }

  setAnalyticsConsent(value: boolean) {
    this.consentedToAnalytics = value;
  }
}

interface GTagEventOptionsInterface {
  event_category: string;
  event_label: string;
}

interface FileDownloadOptionsInterface extends GTagEventOptionsInterface {
  // Enhanced Parameters
  file_extension: string;
  file_name: string;
  link_url: string;
  link_text: string;

  // Custom Parameters
  file_size: string;
}

interface PurchaseReferralOptionsInterface extends GTagEventOptionsInterface {
  merchant: string;
}

interface EnquiryOptionsInterface extends GTagEventOptionsInterface {
  subject: string;
}

interface VideoViewOptionsInterface extends GTagEventOptionsInterface {
  title: string;
  url: string;
  mime: string;
  size: string;
}
