import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServicesModule } from '../services.module';

@Injectable({
  providedIn: ServicesModule
})
export class LocationsService {

  constructor(private readonly $http: HttpClient) { }

  all(): Observable<Location[]> {
    return this.$http.get<Location[]>(`/api/locations`);
  }
}
