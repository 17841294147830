import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from 'server/website/location/schemas/location.schema';
import { ProductCategory } from 'server/website/product-category/schemas/product-category.schema';
import { WebsiteSettings } from 'server/website/settings/schemas/website-settings.schema';
import { find } from 'lodash';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  public settings: WebsiteSettings;
  public address: string[];
  public headOffice: Location;
  public productCategories: ProductCategory[];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.activatedRoute.data.subscribe((data: {
      productCategories: ProductCategory[],
      websiteSettings: WebsiteSettings,
      locations: Location[]
    }) => {
      this.productCategories = data.productCategories;
      this.settings = data.websiteSettings;
      this.headOffice = find(data.locations, { 'head_office': true }) as Location;
    });
  }

  year() {
    return new Date().getFullYear();
  }

}
