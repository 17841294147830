
import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit, ViewChildren, QueryList, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductCategory } from 'server/website/product-category/schemas/product-category.schema';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class HeaderComponent implements OnInit, AfterViewInit {

  @ViewChild('header') headerElement: ElementRef;
  @ViewChildren('subMenu') subMenu: QueryList<ElementRef>
  @ViewChild('mobileMenu') mobileMenu: ElementRef;

  public logoPath: string;
  public productCategories: ProductCategory[];
  public mobileMenuActive = false;

  constructor(
    private renderer: Renderer2,
    private readonly activatedRoute: ActivatedRoute,
  ) { }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {

    this.activatedRoute.data.subscribe((data: {
      productCategories: ProductCategory[]
    }) => {
      this.productCategories = data.productCategories;
    });
  }

  pageIsLowerThanHeader() {
    return (window.scrollY > this.headerElement.nativeElement.clientHeight);
  }

  onWindowScroll(e: Event) {

    if (this.pageIsLowerThanHeader()) {
      this.renderer.addClass(this.headerElement.nativeElement, 'offset');
    } else {
      this.renderer.removeClass(this.headerElement.nativeElement, 'offset');
    }
  }

  onBlur(e: Event) {
    if (!this.pageIsLowerThanHeader()) {
      this.renderer.removeClass(this.headerElement.nativeElement, 'offset');
    }
  }

  onHover(e: Event) {
    this.renderer.addClass(this.headerElement.nativeElement, 'offset');
  }

  subMenuEnter(e: Event) {
    this.renderer.addClass(this.headerElement.nativeElement, '--ffx--sub-menu-active');
  }

  subMenuLeave(e: Event) {
    this.renderer.removeClass(this.headerElement.nativeElement, '--ffx--sub-menu-active');
  }

  toggleMobileMenu() {
    this.mobileMenuActive = !this.mobileMenuActive;
  }

  activateMobileSubMenu(e: MouseEvent) {
    this.renderer.addClass(this.mobileMenu.nativeElement.querySelector('.menu'), '--ffx--sub-menu-active');

    const parent = this.renderer.parentNode(e.target);
    this.renderer.addClass(parent, '--ffx--sub-menu-active');
  }

  deactivateMobileSubMenu() {
    this.renderer.removeClass(this.mobileMenu.nativeElement.querySelector('.menu'), '--ffx--sub-menu-active');
    this.renderer.removeClass(this.mobileMenu.nativeElement.querySelector('.menu-inner .--ffx--sub-menu-active'), '--ffx--sub-menu-active');
  }

}
