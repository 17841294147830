import { NgModule } from '@angular/core';

import { CMSUrlPipe } from './cms-url/cms-url.pipe';
import { FileSizePipe } from './file-size/file-size.pipe';
import { MarkedPipe } from './marked/marked.pipe';
import { NullDashPipe } from './null-dash/null-dash.pipe';
import { SearchPipe } from './search/search.pipe';
import { PluralisePipe } from './pluralise/pluralise.pipe';
import { StripMarkdownPipe } from './marked/strip-markdown.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';

@NgModule({
  declarations: [
    CMSUrlPipe,
    FileSizePipe,
    MarkedPipe,
    NullDashPipe,
    SafeHtmlPipe,
    SearchPipe,
    StripMarkdownPipe,
    PluralisePipe,
  ],
  exports: [
    CMSUrlPipe,
    FileSizePipe,
    MarkedPipe,
    NullDashPipe,
    SafeHtmlPipe,
    SearchPipe,
    StripMarkdownPipe,
    PluralisePipe,
  ],
})
export class PipesModule { }
